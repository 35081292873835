html, body {
  height: 100%;
  overflow: hidden;
}

body {
  min-height: 100%;
  font-family: 'Karla', sans-serif;
}

#root {
  height: 100%;
}

